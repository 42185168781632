<template>
  <div :class="wrapper">
    <template>
      <section class="section contact-us-container business-child m10">
        <div class="form-container">
          <div class="forms-inner hero forms-grid">
            <div class="split-text">
              <h1 class="title">{{ $t('businessTitle') }}</h1>
              <p class="description">{{ $t('businessTitleDes') }}</p>
              <div class="btn_wrapper">
                <btn custom-class="button-link" text="Sign up" :href="urls.STAY_SIGNUP" target="_blank"></btn>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section contact-us-container m10">
        <section class="section split">
          <h2 class="business-content-title">
            {{ $t('businessTraegerTitle') }}
          </h2>
          <div class="slider-mask">
            <div class="w-layout-grid social-quote-grid">
              <div class="split-img social-proof-img-wrapper">
                <img :src="cloudinary.BUSINESS_TRAEGER" class="social-proof-img">
              </div>
              <div class="split-text social-proof-slider-content">
                <div id="w-node-_8529fa68-5844-72e7-c166-58907bbca6f7-5dc58ae8" class="social-proof-slider-content">
                  <img :src="cloudinary.BUSINESS_IMAGE_4" loading="lazy" width="98">
                  <p class="quote-text">{{ $t('businessTraegerDes') }}</p>
                  <div class="sp-person">Josh Olofson</div>
                  <div class="sp-company-title">
                    {{ $t('businessManager1') }}<br/>{{
                      $t('administrador2')
                    }}
                  </div>
                  <div>
                    <span class="text-span">Industry:</span>
                    <strong>{{ $t('navigationBarBusiness') }}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section split">
          <div class="employ-mobile">
            <div class="social-logo-grid">
              <div class="social-logo-card">
                <img :src="cloudinary.BUSINESS_FL_LOGO" loading="lazy" width="56">
                <div class="sp-logo-title">Estado de Florida</div>
              </div>
              <div class="social-logo-card">
                <img :src="cloudinary.GOVERNMENT_SEAL_OF_UTAH" loading="lazy" width="64">
                <div class="sp-logo-title">Estado de Utah</div>
              </div>
              <div class="social-logo-card">
                <img :src="cloudinary.BUSINESS_RSL_LOGO" loading="lazy" width="56">
                <div class="sp-logo-title">Real Salt Lake</div>
              </div>
              <div class="social-logo-card">
                <img :src="cloudinary.BUSINESS_BYUSP_LOGO" loading="lazy" width="58">
                <div class="sp-logo-title"></div>
              </div>
              <div class="social-logo-card">
                <img :src="cloudinary.BUSINESS_IMAGE_4" loading="lazy" width="98">
                <div class="sp-logo-title"></div>
              </div>
              <div class="social-logo-card">
                <img :src="cloudinary.BUSINESS_SLCCHAMBERS_LOGO" loading="lazy" width="113">
                <div class="sp-logo-title"></div>
              </div>
              <div class="social-logo-card">
                <img :src="cloudinary.BUSINESS_VIDANGEL_LOGO" loading="lazy" width="132">
                <div class="sp-logo-title"></div>
              </div>
              <div class="social-logo-card">
                <img :src="cloudinary.BUSINESS_HILTON_LOGO" loading="lazy" width="83">
                <div class="sp-logo-title">Cliffrose Hilton</div>
              </div>
              <div class="social-logo-card">
                <img :src="cloudinary.BUSINESS_MINESSOTA_LOGO" loading="lazy" width="118">
                <div class="sp-logo-title"></div>
              </div>
            </div>
          </div>
        </section>
        <section class="section split three">
          <h2 class="business-content-title">{{ $t('businessTrust') }}</h2>
          <div class="three-col">
            <div class="split-img">
              <img :src="cloudinary.BUSINESS_TRUST_SIMPLE">
              <h4>Configuración simple</h4>
              <p class="quote-text">
                {{ $t('businessTrustSimple') }}
              </p>
            </div>
            <div class="split-img">
              <img :src="cloudinary.BUSINESS_TRUST_HELP">
              <h4>Compruebe los síntomas</h4>
              <p class="quote-text">
                {{ $t('businessTrustHelp') }}
              </p>
            </div>
            <div class="split-img">
              <img :src="cloudinary.BUSINESS_TRUST_KEEP">
              <h4>Manténgase a salvo</h4>
              <p class="quote-text">
                {{ $t('businessTrustSafe') }}
              </p>
            </div>
          </div>
        </section>
        <section class="section split">
          <h2 class="business-content-title">
            {{ $t('empresaEmpleadoMóvil') }}
          </h2>
          <div class="employ-mobile">
            <div class="standard-tab-p tab-pane-img-wrapper-p">
              <div class="standard-tab">
                <img :src="cloudinary.BUSINESS_CD_FREE" loading="lazy">
                <h4>Solución llave en mano</h4>
                <div class="lp-tab-text">{{ $t('mobileEmployeeDesc0') }}</div>
              </div>
              <div class="tab-pane-img-wrapper">
                <img :src="cloudinary.BUSINESS_PASSPORT_ASSESSMENT" width="375">
              </div>
              <div class="standard-tab">
                <img :src="cloudinary.BUSINESS_HEALTH_STANDARD" loading="lazy">
                <h4>Reducir la responsabilidad</h4>
                <div class="lp-tab-text">{{ $t('mobileEmployeeDesc1') }}</div>
              </div>
              <div class="tab-pane-img-wrapper">
                <img :src="cloudinary.BUSINESS_PASSPORT_CLEAR" width="375">
              </div>
              <div class="standard-tab">
                <img :src="cloudinary.BUSINESS_STATUSCLEAR" loading="lazy">
                <h4>Estándares del CDC</h4>
                <div class="lp-tab-text">{{ $t('mobileEmployeeDesc2') }}</div>
              </div>
              <div class="tab-pane-img-wrapper">
                <img :src="cloudinary.BUSINESS_PASSPORT_NOCLEAR" width="375">
              </div>
            </div>
          </div>
          <div data-easing="linear" class="passport-feature-tabs w-tabs">
            <tabs>
              <tab title="Solución llave en mano">
                <div class="tab-pane-img-wrapper">
                  <img :src="cloudinary.BUSINESS_PASSPORT_ASSESSMENT" width="375">
                </div>
              </tab>
              <tab title="Reducir la responsabilidad">
                <img :src="cloudinary.BUSINESS_PASSPORT_CLEAR" width="375">
              </tab>
              <tab title="Estándares del CDC">
                <img :src="cloudinary.BUSINESS_PASSPORT_NOCLEAR" width="375">
              </tab>
            </tabs>
          </div>
        </section>
        <section class="section last split employee-passport">
          <h2 class="business-content-title">
            {{ $t('businessEmployeePassport') }}
          </h2>
          <p class="lp-subtext">
            {{ $t('businessEmployeePassportDes') }}
          </p>
          <div class="content-inner emp-passport-img">
            <img :src="cloudinary.BUSINESS_ACME_DASHBOARD" loading="eager">
          </div>
        </section>
      </section>
    </template>
    <div class="mobile">
      <appstorelinks></appstorelinks>
    </div>
  </div>
</template>

<script>
import AppStoreLinks from '@/components/basic/AppStoreLinks.vue';
import cloudinary from '@/constants/cloudinary';
import Btn from '@/components/basic/Btn.vue';
import urls from '@/constants/urls';
import Tab from '@/components/tabs/Tab.vue';
import Tabs from '@/components/tabs/Tabs.vue';

export default {
  name: 'Business',
  components: {
    AppStoreLinks,
    Btn,
    Tab,
    Tabs,
  },
  data() {
    return {
      title: 'Business',
      wrapper: 'business_component_wrapper',
      thisPage: {},
      urls: {},
    };
  },
  created() {
    this.urls = urls;
    this.cloudinary = cloudinary;
  },
};
</script>

<style lang="scss">
  .content.business {
    padding-left: 0;
    padding-right: 0;
  }
</style>
<style scoped="" lang="scss">
@import '../styles/global/marketing.scss';
.section {
  @extend %section;
}
.business {
  .business_component_wrapper {
    .section {
      .section:first-child {
        margin-top: -8rem;
      }
    }
  }
  .business-child {
    display: flex;
    height: 75vh;
    min-height: 37.5rem;
    align-items: center;
    background-image: url(https://res.cloudinary.com/healthytogether/image/upload/f_auto,q_auto/htio/solutions/lp-hero-bg.png);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    left: 0;
    width: 100%;
    top: -2.4rem;
  }
  .social-logo-grid {
    display: grid;
    width: 100%;
    justify-items: center;
    align-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    .social-logo-card {
      position: relative;
      display: flex;
      width: 10.75rem;
      height: 10.75rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid #efefef;
      border-radius: 1.5rem;
      text-align: center;
      .sp-logo-title {
        position: absolute;
        left: 0;
        top: auto;
        right: 0;
        bottom: 1rem;
        font-size: 0.875rem;
      }
    }
  }
  .w-layout-grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    .social-proof-img-wrapper {
      width: 100%;
      height: 100%;
    }
    .social-proof-slider-content {
      display: flex;
      padding: 1.25rem 2rem 0 1.5625rem;
      flex-direction: column;
      align-items: flex-start;
      img {
        padding-bottom: 1.5625rem;
      }
      .quote-text {
        padding-bottom: 0.9375rem;
      }
      .sp-person {
        margin-bottom: 0.5rem;
        font-weight: 700;
      }
      .sp-company-title {
        margin-bottom: 1rem;
        color: #505050;
      }
    }
    .social-proof-img {
      display: inline-block;
      width: 100%;
      height: 26rem;
      object-fit: cover;
    }
  }
  .standard-tab-p {
    padding-top: 2.1875rem;
    padding-left: 1.25rem;
    .standard-tab {
      display: block;
      width: 30rem;
      height: 12.5rem;
      padding: 1.5rem;
      border-radius: 1.5rem;
      background-color: #f6f6f6;
      margin-top: 1.875rem;
      margin-left: 1.875rem;
      h4 {
        margin-top: 0.625rem;
        margin-bottom: 0.625rem;
        color: #161616;
        font-size: 1.375rem;
        line-height: 1.2em;
        font-weight: 700;
      }
      .lp-tab-text {
        color: #505050;
        font-size: 1.375rem;
        line-height: 1.2em;
      }
    }
  }
  .passport-feature-tabs > div {
    display: flex;
    justify-content: center;
  }
  .content-inner.emp-passport-img {
    position: relative;
    top: 1rem;
    display: flex;
    width: 100%;
    max-width: 59.75rem;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    align-items: flex-end;
    background-color: #fffaf2;
    box-shadow: 0 0 3.5rem 0 rgb(9 30 66 / 10%);
  }
  .lp-subtext {
    font-size: 1.375rem;
    line-height: 1.3em;
    font-weight: 400;
    width: 100%;
    max-width: 45rem;
    margin-right: auto;
    margin-bottom: 4rem;
    margin-left: auto;
    text-align: center;
  }
  .business-content-title {
    width: 100%;
    max-width: 45rem;
    margin-right: auto;
    margin-bottom: 4rem;
    margin-left: auto;
    text-align: center;
  }
  .employee-passport {
    .business-content-title {
      margin-bottom: 1.5rem;
    }
  }
  .section {
    margin-top: 0.625rem;
    padding-top: 5.3rem;
  }
  .section.three {
    padding-left: 0;
    padding-right: 0;
  }
  .section.last {
  @extend %section-last;
  }
  .forms-grid {
    max-width: 33.125rem;
    padding-top: 2rem;
    padding-bottom: 2.5rem;
    margin-top: -4.5rem;
  }
  .slider-mask {
    width: 59.75rem;
    height: 26rem;
    margin-right: auto;
    margin-left: auto;
    border-radius: 1.5rem;
    background-color: #fff;
    box-shadow: 0 1rem 1.5rem 0 rgb(8 35 48 / 12%);
    display: flex;
    overflow: hidden;
    .social-quote-grid {
      height: 100%;
      grid-column-gap: 0;
      grid-row-gap: 0;
      grid-template-columns: 40% 1fr;
      grid-template-rows: auto;
    }
  }
  .employ-mobile {
    width: 59.75rem;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    border-radius: 1.5rem;
    background-color: #fff;
    display: flex;
  }
  .three-col {
    display: flex;
    .split-img {
      padding: 0 0.9375rem;
      text-align: center;
      img {
        width: 100%;
      }
      h4 {
        margin-top: 0.625rem;
        margin-bottom: 0.625rem;
        color: #161616;
        font-size: 1.375rem;
        line-height: 1.2em;
        font-weight: 700;
      }
      p {
        width: 80%;
        margin: 0 auto;
      }
    }
  }
  .emp-passport-img {
    img {
      width: 100%;
    }
  }
  @include bp-xs {
    .mobile {
      z-index: 9;
    }
    .social-logo-grid {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      .social-logo-card {
        width: 100%;
      }
    }
    .three-col {
      flex-flow: column;
      .split-img {
        padding-bottom: 3.125rem;
        h4 {
          font-size: 2.125rem;
          line-height: 1.2em;
        }
        p {
          width: 100%;
          margin: 0 auto;
          font-size: 1.625rem;
          padding-bottom: 3.125rem;
          line-height: 2.5rem;
        }
      }
    }
    .slider-mask,
    .employ-mobile {
      width: 100%;
      height: 100%;
      display: block;
      .w-layout-grid {
        .social-proof-slider-content {
          padding-top: 1.25rem;
          padding-bottom: 0.9375rem;
        }
      }
      .social-quote-grid {
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
      }
      .standard-tab-p {
        padding-top: 0;
        padding-left: 0;
        .standard-tab {
          height: auto;
          width: 100%;
          margin-left: 0;
          text-align: center;
          margin-bottom: 3rem;
          background: none;
        }
      }
      .tab-pane-img-wrapper-p {
        width: 100%;
        .tab-pane-img-wrapper {
          img {
            width: 100%;
          }
        }
      }
    }
    .section {
      padding-left: 1rem;
      padding-right: 1rem;
      .w-tabs {
        display: none;
      }
    }
    .w-layout-grid {
      .social-proof-img {
        height: 18rem;
      }
    }
  }
  @include bp-mediumsmall {
    .social-logo-grid {
      grid-column-gap: 0.625rem;
      grid-row-gap: 0.625rem;
      grid-template-columns: 1fr 1fr;
      .social-logo-card::v-deep {
        width: 100%;
        height: 9.375rem;
      }
    }
    .section {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .w-layout-grid {
      .social-proof-img {
        height: 26rem;
      }
    }
  }
  @include bp-medium {
    .forms-grid {
      max-width: 33.125rem;
      padding-top: 2rem;
      padding-bottom: 2.5rem;
      margin: 0 auto;
      margin-top: -4rem;
    }
    .social-logo-grid {
      grid-column-gap: 1.25rem;
      grid-row-gap: 1.25rem;
      grid-template-columns: 1fr 1fr 1fr;
    }
    .standard-tab-p {
      padding-top: 0;
      padding-left: 0;
      .standard-tab {
        height: auto;
        width: 100%;
        margin-left: 0;
        text-align: left;
      }
    }
    .slider-mask,
    .employ-mobile {
      .section {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
    .section {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
  @include bp-mediumlarge {
    .three-col {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
    .slider-mask,
    .employ-mobile {
      width: 100%;
      display: flex;
      .social-quote-grid {
        grid-template-columns: 40% 1fr;
        grid-template-rows: auto;
      }
      .tab-pane-img-wrapper-p {
        width: 50%;
        .tab-pane-img-wrapper {
          text-align: left;
          img {
            width: 90%;
          }
        }
      }
      .standard-tab-p {
        margin-top: 3rem;
        width: 50%;
        .standard-tab {
          width: 100%;
          text-align: left;
        }
      }
      .section {
        margin-top: 0.625rem;
        padding-top: 5.3rem;
        padding-left: $responsive-padding;
        padding-right: $responsive-padding;
        .w-tabs {
          display: block;
        }
      }
    }
    .social-logo-grid {
      grid-column-gap: 1.25rem;
      grid-row-gap: 1.25rem;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      .social-logo-card {
        width: 9.375rem;
        height: 9.375rem;
      }
    }
    .mobile {
      z-index: 9;
    }
    .forms-grid {
      margin-left: 3rem;
      margin-top: -4rem;
    }
    .section {
      .w-tabs {
        display: block;
      }
      .tab-pane-img-wrapper-p {
        display: none;
      }
    }
  }
  @include bp-large {
    .slider-mask,
    .employ-mobile {
      width: 59.75rem;
      .section {
        margin-top: 0.625rem;
        padding-top: 5.3rem;
        padding-left: $responsive-padding;
        padding-right: $responsive-padding;
      }
      .standard-tab-p {
        margin-top: 6rem;
        .standard-tab {
          width: 30rem;
        }
      }
    }
    .social-logo-grid {
      grid-column-gap: 1.25rem;
      grid-row-gap: 1.25rem;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      .social-logo-card {
        width: 10.75rem;
        height: 10.75rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
  }
}
.mobile {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $white;
  box-shadow: 0 -2px 4px rgba(8, 35, 48, 0.1);
  @include bp-medium {
    display: none;
  }
  .stores {
    display: flex;
    justify-content: space-between;
    margin: 1.5rem;
  }
  @include bp-mediumsmall {
    display: none;
  }
}
.hero {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding-top: 0;
  @include bp-xlarge {
    flex-direction: row;
    text-align: left;
  }
  & > div {
    flex: 0 1 48%;
  }
  .title {
    font-size: 1.5rem;
    line-height: 1.6em;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include bp-xlarge {
      font-size: 3rem;
      line-height: 3.75rem;
      margin-top: 0;
      margin-bottom: 1.5rem;
    }
  }
  .description {
    font-size: 1rem;
    line-height: 1.7rem;
    margin-bottom: 1.5rem;
  }
}
.split-text {
  display: flex;
  flex-direction: column;
  @include bp-medium {
    min-width: 100%;
  }
  .btn_wrapper{
    text-align: center;
  }
}
.button-link {
  color: #ffffff;
  padding: 1rem;
  border-radius: 6.25rem;
  transition: all 240ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  background-color: #0073ff;
  order: 4;
  width: 100%;
  padding: 0.75rem 1.5rem;
  @include bp-mediumsmall {
    width: auto;
    min-width: 9.5rem;
  }
  font-size: 0.9375rem;
  text-align: center;
  font-weight: 700;
}
</style>
